<template>
  <section class="invoice-preview-wrapper">
    <b-alert
        variant="danger"
        v-if="!invoice">
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'apps-invoice-list'}">
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>
    <b-row class="invoice-preview" v-if="invoice">
      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8">
        <b-card
            no-body
            class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img :src="require('@/assets/images/logo/intellyscale.svg')" style="width: 150px">
                </div>
                <b-card-text class="mb-0" v-html="staticInvoiceData.company_address">
                </b-card-text>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ $t('Invoice') }}
                  <span class="invoice-number" v-if="invoice.order_number">
                   #{{ invoice.order_number }}
                  </span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title w-auto">
                    {{ $t('Date Issued') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.created_at | moment }}

                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title w-auto">
                    {{ $t('Due Date') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoice.created_at | moment }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
              class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
              >
                <h6 class="mb-2">
                  {{ $t('Invoice to') }}:
                </h6>
                <p class="card-text mb-25">
                  {{ invoice.address.name }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.address.street }} {{ invoice.address.postal }}
                </p>

                <p class="card-text mb-25">
                  {{ invoice.address.city }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.address.country }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                  xl="4"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    {{ $t('Payment Details') }}:
                  </h6>
                  <table>
                    <tbody>
                    <tr>
                      <td class="pr-1">
                        {{ $t('Total Due') }}:
                      </td>
                      <td><span class="font-weight-bold">  {{ invoice.total_credits }} $</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{ $t('Payment method') }}:
                      </td>
                      <td> {{ $t('Bank transfer') }}</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
              ref="invoiceTable"
              responsive
              :items="invoice.products"
              :fields="fields"

          >


            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">

                {{ data.item.product.name }}
              </b-card-text>
              <b-card-text class="text-nowrap" v-if="invoice.type == 'buy'">
                {{ $t('Credits bought') }}
              </b-card-text>
              <b-card-text class="text-nowrap" v-else>
                {{ $t('Credits bought for Distribution') }}
              </b-card-text>
            </template>
            <template #cell(creditsAmount)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.price }}
              </b-card-text>
            </template>
            <template #cell(singlePrice)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.qty }}
              </b-card-text>
            </template>
            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.total_price }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold"> {{ $t('Contact person') }}:</span>
                  <span class="ml-75">Tonny Shelby</span>
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Subtotal') }}:
                    </p>
                    <p class="invoice-total-amount">

                      {{ invoice.total_credits }} $
                    </p>
                  </div>

                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Tax') }}:
                    </p>
                    <p class="invoice-total-amount">
                      0%
                    </p>
                  </div>
                  <!--                  <hr class="my-50">-->
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Total') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoice.total_credits }} $
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Note -->
          <b-card-body class="invoice-padding pt-4 w-50">
            <span class="font-weight-bold"> {{ $t('Note') }}: </span>
            <span>   {{
                $t('It was a pleasure working with you and your team. We hope you will keep us in mind for future Projects. Thank You!')
              }}</span>
          </b-card-body>
          <hr class="invoice-spacing">
          <b-card-body v-if="staticInvoiceData">
            <b-row>
              <b-col
                  xl="3"
                  cols="12"
                  md="6">
                <b-card-text class="mb-0" v-html="staticInvoiceData.footer_col_1">

                </b-card-text>

              </b-col>
              <b-col
                  xl="3"
                  cols="12"
                  md="6">
                <b-card-text class="mb-0" v-html="staticInvoiceData.footer_col_2">
                </b-card-text>
              </b-col>
              <b-col
                  xl="3"
                  cols="12"
                  md="6">
                <b-card-text class="mb-0" v-html="staticInvoiceData.footer_col_3">
                </b-card-text>
              </b-col>
              <b-col
                  xl="3"
                  cols="12"
                  md="6"
              >
                <b-card-text class="mb-0" v-html="staticInvoiceData.footer_col_4">
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions">
        <b-card>
          <b-button
              @click="getPdf(invoice.id)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="w-100">
            {{ $t('Download') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'

import InvoiceSidebarSendInvoice from './InvoiceSidebarSendInvoice.vue'
import moment from 'moment'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,

    InvoiceSidebarSendInvoice,
  },

  data() {
    return {
      staticInvoiceData: null,
      invoice: null,

    }
  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('DD.MM.YYYY')
    }
  },

  methods: {
    getPdf(id) {
      this.$http.get(`client/order/generate/${id}`, {responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], {type: 'application/pdf'})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `invoice-${this.invoice.order_number}.pdf`
            link.click()
          })
          .catch(error => {
          })
    },

    getInvoiceStaticData() {
      this.$http.get(`client/invoicesdata`,)
          .then(response => {
            this.staticInvoiceData = response.data
          })
    },

    getInvoice() {
      this.$http.get(`client/orders/${this.$route.params.id}`)
          .then(response => {
            this.invoice = response.data

          })

    },
    printInvoice() {
      window.print()
    }
  },
  mounted() {

    this.getInvoice()
    this.getInvoiceStaticData()
  },
  computed: {
    fields() {
      return [
        {key: 'item', label: this.$i18n.t('item')},
        {key: 'creditsAmount', label: this.$i18n.t('creditsAmount')},
        {key: 'singlePrice', label: this.$i18n.t('singlePrice')},
        {key: 'price', label: this.$i18n.t('price')},
      ]
    }
  }
}


</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
